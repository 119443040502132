import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CollapsedItemWrapper } from '../../../Common/styled/CollapsedItemWrapper';

const useStyles = makeStyles((theme) => ({
  collapsed: {
    width: '100%',
    marginBottom: 2,
  },
  ellipsis: {
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    '& > span': {
      color: theme.palette.primary.main,
      '&.attr': {
        fontWeight: 700,
        color: theme.palette.primary.dark,
      },
    },
  },
}));

const getType = (item) => {
  if (item.teamMember) return 'shared.with.team.member.description';
  if (item.userGroup) return 'shared.with.user.group.description';
  return 'admin.only.description';
};

export const OpenStowRsManagementDataCollapse = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <CollapsedItemWrapper>
      <Box className={classes.collapsed}>
        {t(getType(item))}
      </Box>
      <Box className={classes.ellipsis}>
        <Typography component="span" className="attr">
          {`${t('token')}: `}
        </Typography>
        <Typography component="span">
          {item && item.token}
        </Typography>
      </Box>
    </CollapsedItemWrapper>
  );
};

export default OpenStowRsManagementDataCollapse;
