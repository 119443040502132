import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import { Box, Container } from '@material-ui/core';
import {
  AddBoxOutlined,
  AccountCircle,
  Ballot,
  DeleteTwoTone,
  GroupWork,
  SupervisedUserCircle,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { GET_STOW_RS_ENDPOINTS } from '../../../../queries/OpenStowRs/OpenStowRs';
import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { PageListContent } from '../../../Common/styled/PageContent';
import { isEmpty } from '../../../../utils/ObjectUtils';
import Loading from '../../../Common/Loading';
import { CardWrapperUI } from '../../../Common/styled/CardWrapperUI';
import TableCollapse from '../../../../componentsUI/TableCollapse';
import { OpenStowRsManagementDataCollapse } from './OpenStowRsManagementDataCollapse';
import { DeleteOpenRsAccessDialog } from './modal/DeleteOpenRsAccessDialog';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: theme.palette.grey.A200,
    marginTop: -2,
  },
}));

const fieldNames = [
  { label: 'user', id: 'userUuid', width: 265, field: 'NAME', direction: 'ASC' },
  { label: 'token', id: 'token', width: 520 },
];

export const OpenStowRsList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const user = useSelector((state) => state.userInterface.user);
  const [selected, setSelected] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { loading, data } = useQuery(
    GET_STOW_RS_ENDPOINTS, {
      variables: {
        userUuid: user.uuid,
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const getType = (item) => {
    if (item.teamMember) return 'shared.with.team.member';
    if (item.userGroup) return 'shared.with.user.group';
    return 'admin.only';
  };

  const getItemIcon = (item) => {
    const type = getType(item);
    switch (type) {
      case 'shared.with.team.member':
        return <SupervisedUserCircle />;
      case 'shared.with.user.group':
        return <GroupWork />;
      case 'admin.only':
      default:
        return <AccountCircle />;
    }
  };

  const getUserDescription = (item) => {
    const type = getType(item);
    switch (type) {
      case 'shared.with.team.member':
        return item.teamMember.user && `${item.teamMember.user.name} ${item.teamMember.user.surname}`;
      case 'shared.with.user.group':
        return item.userGroup.name;
      case 'admin.only':
      default:
        return user.uuid === item.userUuid ? `${user.name} ${user.surname}` : t('not.available');
    }
  };

  const getTitle = (item) => (
    <Box className={classes.title}>
      {getItemIcon(item)}
      {getUserDescription(item)}
    </Box>
  );

  const getSubTitle = (item) => (
    <Box className={classes.subtitle}>
      {t(getType(item))}
    </Box>
  );

  const openStowRs = data && data.openStowRsEndpoints;
  const openStowRsAccess = selected && selected.length && openStowRs && openStowRs[selected[0]];

  const handleOpenAdd = () => navigate('/hospital/settings/open-stow-rs/new');
  const handleViewDetail = () => navigate(`/hospital/settings/open-stow-rs/view/${openStowRsAccess.uuid}`);
  const handleOpenDelete = () => setShowDeleteModal(true);
  const handleCloseDelete = ({ status }) => {
    if (status && status === 'deleted') setSelected([]);
    setShowDeleteModal(false);
  };
  const handleRowViewDetail = (evt, index) => {
    evt.stopPropagation();
    const indexAccess = openStowRs[index];

    navigate(`/hospital/settings/open-stow-rs/view/${indexAccess.uuid}`);
  };

  const tableRowButtons = [{ Icon: Ballot, tooltip: 'view.details' }];
  const headerButtons = [
    { name: 'add.endpoint', icon: AddBoxOutlined, I: 'delete', data: hospital, handleClick: handleOpenAdd },
    { name: 'divider2', type: 'divider' },
    { name: 'view.details', icon: Ballot, handleClick: handleViewDetail, disabled: selected.length === 0 },
    { name: 'remove.endpoint', icon: DeleteTwoTone, I: 'delete', data: hospital, handleClick: handleOpenDelete, disabled: selected.length === 0 },
  ];

  return (
    <>
      <Navbar>
        <SectionBar title="hospital.settings.open.stow.rs.manage" items={headerButtons} />
      </Navbar>
      <DeleteOpenRsAccessDialog
        open={showDeleteModal}
        onClose={handleCloseDelete}
        openStowRsAccess={openStowRsAccess}
      />
      <Container maxWidth="lg">
        <PageListContent>
          {loading && isEmpty(data) ? <Loading /> : (
            <CardWrapperUI>
              {(openStowRs && openStowRs.length) ? (
                <TableCollapse
                  responsive={false}
                  fieldNames={fieldNames}
                  items={openStowRs}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  GetCollapse={OpenStowRsManagementDataCollapse}
                  handleGoto={handleRowViewDetail}
                  ActionButtons={tableRowButtons}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : (
                <AlertWrapperUI>
                  <AlertUI severity="info" title="Info">
                    {t('no.stow.rs.enabled')}
                  </AlertUI>
                </AlertWrapperUI>
              )}
            </CardWrapperUI>
          )}
        </PageListContent>
      </Container>
    </>
  );
};
