import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const CollapsedItemWrapper = withStyles(() => ({
  root: {
    fontSize: '.9375em',
    padding: '0 2em 1em',
    maxWidth: 540,
  },
}))(Box);

export default CollapsedItemWrapper;
