import React, { forwardRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { DELETE_OPEN_STOW_RS_ACCESS, GET_STOW_RS_ENDPOINTS } from '../../../../../queries/OpenStowRs/OpenStowRs';
import { networkErrorParse } from '../../../../../utils/ErrorGraphQLUtils';
import { Margin } from '../../../../Common/styled/Margins';
import { Column } from '../../../../Common/styled/Groups';
import { ExtraBold, Span } from '../../../../Common/styled/Text';
import { GreyLabel } from '../../../../Common/styled/GreyLabel';
import Loading from '../../../../Common/Loading';
import { AlertUI } from '../../../../../componentsUI/Alert';
import { getFormattedDate } from '../../../../../utils/dateTimeUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
      '@media (max-width:600px)': {
        margin: 5,
        padding: 10,
        '& > div': {
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteOpenRsAccessDialog = ({ open, onClose, openStowRsAccess = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const user = useSelector((state) => state.userInterface.user);
  const { uuid: openStowRsAccessUuid } = openStowRsAccess;
  const [serverError, setServerError] = useState(false);

  const [deleteOpenStowRsAccess, { loading, called }] = useMutation(DELETE_OPEN_STOW_RS_ACCESS, {
    onCompleted() {
      onClose({ status: 'deleted' });
      toast(t('open.stow.rs.endpoint.removed'), { className: 'toast-info' });
    },
    onError(error) {
      const errorMsg = networkErrorParse(error) || 'server.error';
      setServerError(errorMsg);
    },
    refetchQueries: [{
      query: GET_STOW_RS_ENDPOINTS,
      variables: {
        userUuid: user.uuid,
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
    }],
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setServerError(false);
    onClose({ status: 'close' });
  };

  const handleDeleteAccess = () => {
    setServerError(false);
    deleteOpenStowRsAccess({ variables: { openStowRsAccessUuid } }).then();
  };

  const tokenPreview = openStowRsAccess && openStowRsAccess.token && openStowRsAccess.token.slice(0, 32);
  const privateEndpoint = openStowRsAccess && !openStowRsAccess.teamMember && !openStowRsAccess.userGroup;
  const accessUser = openStowRsAccess && openStowRsAccess.teamMember && openStowRsAccess.teamMember.user;
  const accessGroup = openStowRsAccess && openStowRsAccess.userGroup;

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{t('open.stow.rs.confirm.delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('open.stow.rs.delete.confirmation.message')}
        </DialogContentText>
        <Margin mx-0 mt-0>
          <Column>
            <Span>
              <GreyLabel>{`${t('open.stow.rs.endpoint.token')}: `}</GreyLabel>
              <ExtraBold>{`${tokenPreview}...`}</ExtraBold>
            </Span>
            <Span>
              <GreyLabel>{`${t('created.at')}: `}</GreyLabel>
              <ExtraBold>{openStowRsAccess && getFormattedDate({ date: openStowRsAccess.createdAt })}</ExtraBold>
            </Span>
            {privateEndpoint && (
              <Span>
                <GreyLabel>{t('open.stow.rs.not.shared')}</GreyLabel>
              </Span>
            )}
            {accessUser && (
              <Span>
                <GreyLabel>{`${t('open.stow.rs.shared.with.user')}: `}</GreyLabel>
                <ExtraBold>{`${accessUser.name} ${accessUser.surname}`}</ExtraBold>
              </Span>
            )}
            {accessGroup && (
              <Span>
                <GreyLabel>{`${t('open.stow.rs.shared.with.group')}: `}</GreyLabel>
                <ExtraBold>{accessGroup.name}</ExtraBold>
              </Span>
            )}
          </Column>
        </Margin>
        {called && loading && <Loading />}
        {serverError && <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>}

      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleDeleteAccess}
          disabled={loading}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
