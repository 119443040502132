/*
Queries for:
-----
Case
Groups
Hospitals
Notifications
Patients
Studies
Teams
Users
*/
import gql from 'graphql-tag';

// Case
export const GET_STOW_RS_ENDPOINTS = gql`
  query ($userUuid: ID!, $hospitalUuid: ID!) {
    openStowRsEndpoints(userUuid: $userUuid, hospitalUuid: $hospitalUuid) {
      uuid
      userUuid
      token
      createdAt
      hospital {
        uuid
        name
        owner
      }
      teamMember {
        uuid
        user {
          uuid
          name
          surname
        }
        role
      }
      userGroup {
        uuid
        name
      }
    }
  }
`;

export const GET_STOW_RS_ENDPOINT_DETAIL = gql`
  query($uuid: ID!) {
    openStowRsEndpoint(uuid: $uuid) {
      uuid
      userUuid
      token
      createdAt
      hospital {
        uuid
        name
        owner
      }
      teamMember {
        uuid
        user {
          uuid
          name
          surname
        }
        role
      }
      userGroup {
        uuid
        name
      }
    }
  }
`;

export const CREATE_OPEN_STOW_RS_ACCESS = gql`
  mutation ($input: CreateOpenStowRsAccessInput!) {
    createOpenStowRsAccess(input: $input) {
      uuid
      userUuid
      token
      hospital {
        uuid
        name
        owner
      }
      teamMember {
        uuid
        user {
          uuid
          name
          surname
        }
        role
      }
      userGroup {
        uuid
        name
      }
    }
  }
`;

export const DELETE_OPEN_STOW_RS_ACCESS = gql`
  mutation ($openStowRsAccessUuid: ID!) {
      deleteOpenStowRsAccess(openStowRsAccessUuid: $openStowRsAccessUuid)
  }
`;
