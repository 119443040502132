import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { OpenStowRsList } from '../../../../components/Hospital/Management/OpenStowRs/OpenStowRsList';

export default () => (
  <Layout type="settings">
    <OpenStowRsList />
  </Layout>
);
